.lib-product_details{
    display: flex;
    flex-direction: column;
}

.breadcrumb-wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

.product-details-page .lib-product_info {
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 40px;
}
.lib-product_info_product-normal-price.discount{
    text-decoration: line-through;
    color: var(--color-accent-light);
    font-size: var(--fz-small);
    margin-bottom: 0;
}

.lib-product_info {
    --header-accent-ff: var(--accent-ff);
    --header-color: var(--color-accent);
    font-family: var(--header-accent-ff);
    color: var(--header-color);
    display: flex;
}

.lib-product_info .container {
    display: flex;
    justify-content: flex-end;
    padding-left: 40px;
    padding-right: 40px;
}

.lib-product_info .left-part {
    max-width: 60%;
}

.lib-product_info_content{
    width: 360px;
    float: right;
}
.lib-product_info_product-title{
    font-size: var(--fz-normal-bigger);
}
.lib-product_info_product-subtitle {
    font-size: var(--fz-normal);
    font-weight: normal;
    margin: 0;
}
.lib-product_info_product-normal-price{
    font-size: var(--fz-normal-bigger);
}
.lib-product_info_colour {
    font-size: var(--fz-normal);
}
.lib-product_info_size_list {
    display: flex;
}
.lib-product_info_size_list li {
    list-style: none;
    padding-right: var(--size-small);
    font-size: var(--fz-mid);
}
.lib-product_info_size_list{
    padding-left: 0;
}
.lib-product_info_size button.table-size{
    text-decoration: underline;
    color: var(--color-accent-light);
    font-size: var(--fz-normal);
    background: transparent;
    border: none;
    cursor: pointer;
}
.lib-product_cart_btn{
    margin-top: 10px;
    width: 100%;
}
.size-title{
    margin-top: var(--size-bigger);
    font-size: var(--fz-normal);
}
.size_list {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--header-color);
    border: 1px solid var(--color-accent-light);
    padding: var(--size-small);
    border-radius: var(--size-small);
    transition: .3s ease;
}
.size_list:disabled, .size_list:hover:disabled {
    opacity: .7;
    user-select: none;
    border: 1px solid var(--color-border-lightest);
    box-shadow: none;
    cursor: default;
}
.size_list.error:not([disabled]) {
    border: 1px solid var(--color-error);
}
.lib-product_info_content .size-error {
    color: var(--color-error);
    font-size: var(--fz-small);
}
.size_list.active {
    border: 1px solid var(--color-accent);
    box-shadow: 0 0 0 1px var(--color-accent);
}
.size_list:hover{
    border: 1px solid var(--color-accent);
    box-shadow: 0 0 0 1px var(--color-accent);
}
.lib-product_info_wishlist {
    margin-top: var(--size-small);
    display: flex;
    align-items: center;
    font-size: var(--fz-normal);
    color: var(--color-accent-light);
    cursor: pointer;
}
.lib-product_info_product_description{
    width: 100%;
    margin-top: 60px;
}
@media (max-width: 762px) {
    .lib-product_cart_btn{
        margin-top: 0;
    }
    .product-details-page .cart-container {
        position: fixed;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        gap: 8px;
        width: 100%;
        background-color: white;
        padding: 15px 15px max(15px, env(safe-area-inset-bottom));
        border-top: 1px solid var(--color-border-lightest);
    }
    .product-details-page .cart-container .lib-product_info_submit {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .product-details-page .cart-container .lib-product_info_wishlist {
        width: 50px;
        height: 50px;
        background: #f0f0f0;
        border-radius: 4px;
        padding: 15px;
        margin: 0;
    }
    .product-details-page .cart-container .lib-product_info_wishlist span {
        display: none;
    }
    .product-details-page .cart-container .lib-product_info_wishlist button {
        padding: 0;
        height: 23px;
        width: 23px;
    }
    .product-details-page .cart-container .lib-product_info_wishlist > div {
        height: 23px;
        width: 23px;
    }
    .product-details-page .lib-product_info {
        padding-top: 0;
    }
    .lib-product_info .container{
        flex-direction: column;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .lib-product_info .left-part {
        max-width: 100%;
    }
    .lib-product_info_content .lib-swatches{
        display: flex;
        justify-content: center;
    }
    .lib-product_info_size_list{
        display: flex;
        justify-content: center;
    }
    .lib-product_info_wishlist{
        display: flex;
        justify-content: center;
    }
    .lib-product_info_wishlist{
        margin-top: 20px;
    }
    .lib-product_info_product_description{
        padding: 30px;
        margin: 0;
        width: 100%;
    }
    .lib-product_info_content{
        width: 100%;
        padding: 0 10px 10px;
        text-align: center;
    }
}

.sidebar-social__instagram {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    opacity: .85;
    transition: all .2s ease;
    background: linear-gradient(45deg, #ffb700 14.65%, #ff6800 31.68%, #cb217d 54.72%, #7e00aa 85.35%);
}
.lib-product_instagram_container{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}