:root  {
    /*---------- FONTS ---------*/
    --accent-ff-light: 'SourceSansPro-Light', sans-serif;
    --accent-ff: 'Cera Pro Regular', sans-serif;
    --accent-ff-bold: 'Cera Pro Medium', sans-serif;

    --line-h-bigger: 22px;
    --line-h-normal: 20px;
    --line-h-normal-smaller: 18px;
    --line-h-small: 16px;
    --line-h-smaller: 12px;

    --fz-tinier: 10px;
    --fz-tiny: 12px;
    --fz-smallest: 13px;
    --fz-smaller: 14px;
    --fz-small: 14px;
    --fz-mid: 15px;
    --fz-normal: 17px;
    --fz-normal-bigger: 20px;
    --fz-biggest: 30px;

    /*----------- COLORS ---------*/

    --color-lightest: #fff;
    --color-accent: #655853;
    --color-accent-light: #a68e7f;
    --color-grayish: #e5e5e5;
    --color-border-accent: #e6e6e6;
    --color-border-lightest: #E5E5E5;
    --color-dark-light: #333333;
    --color-arrow-black: #000000;
    --color-error: #8d0624;
    --color-accent-brown: #dbd7d2;
    --bg-color: var(--color-lightest);

    /*Card additional colors*/
    --color-focused-input: #c5a995;
    --color-hint: #717171;
    --color-link-card: #b8967e;
    --color-border-card: #d5d5d5;
    --color-btn-hover-card: #c8c8c8;
    --coror-divides-line-card: #e6e6e6;
    --color-card-aside-background: #fafafa;

    /*------------ SIZES -----------*/
    --size-min-small: 3px;
    --size-smaller: 6px;
    --size-small: 10px;
    --size-mid-low: 12px;
    --size-mid: 15px;
    --size-mid-bigger: 16px;
    --size-normal-small: 18px;
    --size-normal: 20px;
    --size-normal-big: 24px;
    --size-normal-mid: 24px;
    --size-big: 28px;
    --size-bigger: 30px;
    --size-biggest: 32px;
    --size-fourty: 40px;
    --size-fifty: 50px;
    --size-sixty: 60px;
    --size-eigthty: 80px;

    /* CONTAINER WIDTH */
    --size-content-big: 110px;
    --size-normal-bigger: 24px;

    /* CONTAINER WIDTH */
    --size--margin-big: 1500px;
}
