.line-top {
    border-top: 1px solid var(--color-grayish);
}

.line-bottom {
    border-bottom: 1px solid var(--color-grayish);
}

.main-container {
    margin: 0 auto;
    max-width: 1440px;
    width: calc(100% - 60px);
}

@media (max-width: 700px) {
    .main-container {
        width: calc(100% - 40px);
    }
}

@media (max-width: 500px) {
    .main-container {
        width: calc(100% - 20px);
    }
}
