.scroll-slider {
    margin-bottom: 60px;
    position: relative;
    display: flex;
}

.scroll-slider_content {
    width: 100%;
    display: flex;
    padding-left: 30px;
    padding-right: 60px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.scroll-slider_content-inner {
    height: 671px;
    overflow: auto;
    width: 100%;
}

.scroll-slider_content-inner::-webkit-scrollbar {
    display: none;
}

.scroll-slider .image-wrapper {
    margin-bottom: 40px;
    width: 100%;
    transition: opacity .3s ease-in-out;
}

.scroll-slider .image-inner {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 1px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.scroll-slider .scroll-slider_thumbnails {
    margin-bottom: 30px;
    margin-right: 60px;
}

.scroll-slider .thumbnail-image {
    display: block;
    width: 56px;
    height: auto;
    margin-bottom: 10px;
    padding: 0;
    cursor: pointer;
    overflow: visible;
    transition: .3s ease;
}

.scroll-slider .thumbnail-image.active {
    opacity: .5;
}
