.header-input-form {
    width: 100%;
}

.header-input-search {
    height: 36px;
    font-size: var(--fz-normal);
    padding: 0 16px 0 40px;
    line-height: normal;
    background-color: rgba(245,243,243,.64);
    color: var(--color-accent);
    border-radius: 4px;
    border: 0;
}
.header-icon-search {
    margin: 5px;
}
.svg-icon.icon-search{
    display: block;
    margin-right: 10px;
    color: var(--color-accent);
}
.css-6j8wv5-Input{
margin-left: 20px;
}

.dropdown-indicator-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 30px;
}

.react-select__control .react-select__value-container {
    padding: 2px 4px;
}

.lib-sidebar .header-icon-search {
    color: var(--color-accent);
}
