.lib-custom-button{
    font-family: var(--accent-ff);
    width: 100%;
    border-radius: 8px;
    height: 50px;
    background-color: var(--color-accent-light);
    color: white;
    border: none;
    font-size: var(--fz-normal);
    display: flex;
    justify-content: center;
    align-items: center;
}
.lib-custom-button_button-loader{
    margin-right: 10px;
    color: white;
    margin-top: 3px;
}

.lib-custom-button:hover {
    background-color: var(--color-accent);
}

.lib-custom-button.primary {
    background: var(--color-lightest);
    border: 1px solid var(--color-accent-light);
    color: var(--color-accent-light);
}
.lib-custom-button.primary:hover {
    background-color: var(--color-accent);
    color: white;
}
.lib-custom-button.dark{
    background-color: var(--color-accent-light);
}
.lib-custom-button.logout{
    background-color: inherit;
    width: 60px;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--color-accent);
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: var(--fz-smaller);
    transition: .2s;
}
.lib-custom-button.logout:hover > span:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    border-width: 0 0 1px;
    border-style: solid;
}
.lib-custom-button:disabled, .lib-custom-button:disabled:hover {
    background-color: var(--color-grayish);
    cursor: default;
    user-select: none;
}
@media all and (max-width: 750px) {
    .lib-custom-button.logout {
        top: 0;
    }
}
