#root {
    width: initial;
}
body {
    font-size: initial;
    background-color: var(--color-lightest);
}

.layout {
    --top-nav-height: 50px;
}

.layout__content-wrapper {
    height: 100%;
    width: 100%;
    padding-top: var(--top-nav-height);
    display: flex;
    flex-direction: column;
}
