.lib-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 4px;
}

.lib-card .lib-card__picture {
    object-fit: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    display: block;
    margin-bottom: 8px;
    padding-top: 162.5%;
    width: 100%;
    position: relative;
}

.lib-card .discount-label {
    position: absolute;
    top: 0;
    left: 4px;
    width: 70px;
    padding: 2px 0;
    text-align: center;
    font-size: var(--size-normal-small);
    color: var(--color-dark-light);
    background: rgba(196, 169, 150, .7);
    border-top-left-radius: 4px;
    font-family: var(--accent-ff-bold);
    border-bottom-right-radius: 4px;
}

.lib-card .lib-card__info {
    padding-bottom: 25px;
}

.lib-card__info-content {
    display: flex;
    flex-direction: column;
}
.lib-card__heart_wh {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    padding: 0;
    margin: 0;
    z-index: 2;
}
.lib-card .lib-card__info-supsale {
    display: flex;
    line-height: var(--line-h-normal);
    color: var(--color-accent-light);
    font-size: var(--fz-tiny);
    font-family: var(--accent-ff);
    min-height: 10px;
}

.lib-card .lib-card__info-supsale span {
    margin-right: 15px;
}
.lib-card .lib-card__info-supsale span:last-child {
    margin-right: 0;
}

.lib-card .lib-card__info-title {
    line-height: 1.5;
    color: var(--color-accent);
    font-size: var(--fz-mid);
    font-family: var(--accent-ff);
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.lib-card:hover .lib-card__info-title {
    color: var(--color-accent-light);
}

.lib-card .lib-card__info-price {
    line-height: var(--line-h-normal);
    color: var(--color-accent);
    font-size: var(--fz-mid);
    font-family: var(--accent-ff);
    margin-right: 10px;
    white-space: nowrap;
}
.lib-card .sale{
    color: var(--color-accent-light)!important;
    font-size: var(--fz-tiny);
    line-height: 1.4;
    margin-right: 0;
    position: relative;
    white-space: nowrap;
}
.lib-card .sale::before{
    border-top: 1px solid var(--color-accent-light);
    position: absolute;
    content: "";
    right: 0;
    top: 40%;
    left: 0;
}
.lib-card__info-price-content {
    margin-top: 4px;
    display: flex;
    align-items: center;
}
.lib-card .lib-card__info .lib-swatches .lib-swatches__item button {
    height: 14px;
    width: 14px;
}

.lib-card .lib-card__info .lib-swatches .lib-swatches__item button:after {
    width: 20px;
    height: 20px;
}
.lib-card__info{
    height: 100px;
}
@media (max-width: 768px) {
    .lib-card__heart_wh{
        right: 1px;
        top: 5px;
    }
    .lib-card .discount-label {
        font-size: var(--fz-normal);
        width: 60px;
    }

    .lib-card .lib-card__info .lib-swatches .lib-swatches__item button {
        height: 12px;
        width: 12px;
    }
}
