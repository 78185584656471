.accordion-item.collapsed {
    display: none;
}
.accordion-item.reverse {
    transform: rotate(0.5turn);
    transition: all .3s ease-out;
}
.accordion-item.position {
    transform: rotate(0turn);
    transition: all .3s ease-out;
}
.accordion-item.expanded {
    display: block;
}

.accordion-button {
    display:flex;
    width: 100%;
    border: none;
    background-color: var(--color-lightest);
    padding-right: 10px;
    font-size: var(--fz-normal);
    list-style: none;
    border-bottom: 1px solid var(--color-grayish);
    padding-bottom: 10px;
    padding-top: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--color-accent);
}
.accordion-button:focus{
    outline: none;
}
@media (max-width: 768px) {
.accordion-item.expanded{
    padding-left: 10px;
}

}
