.lib-sidebar {
    transform: translateX(-101%);
    transition: .3s;
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--color-lightest);

    --width-arrow: 23px;
    --heigth-arrow: 23px;
}

.lib-sidebar .accordion-button {
    margin: 0;
    padding: 0;
}

.lib-sidebar .lib-sidebar__content {
    height: 80vh;
    overflow-y: auto;
}

.lib-sidebar.open {
    transform: translateX(0);
}

.lib-sidebar__header {
    width: 100%;
    border-bottom: 1px solid var(--color-grayish);
    display: flex;
    justify-content: space-between;
}

.header-search-field {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--size-mid);
}

.header-search-field__input {
    border: none;
    color: var(--color-accent);
    background-color: transparent;
    font-size: var(--fz-mid);
    margin-left: var(--size-mid);
    width: calc(100% - 50px);
}

.lib-sidebar__header-close {
    border: none;
    background-color: transparent;
    width: 55px;
    border-left: 1px solid var(--color-grayish);
}

.lib-sidebar__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.lib-sidebar__item {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: var(--accent-ff);
    padding: var(--size-mid) var(--size-normal);
    font-size: var(--fz-mid);
    color: var(--color-accent);
}

.lib-sidebar__line {
    margin: -7px var(--size-mid);
    border-bottom: 1px solid #EBE6E1;
}

.lib-sidebar__item-text {
    width: 98%;
}

.lib-sidebar__item-arrow {
    width: var(--width-arrow);
    height: var(--heigth-arrow);
    cursor: pointer;
}

.lib-sidebar a {
    text-decoration: none;
    color: var(--color-accent);
    font-size: var(--fz-mid);
}

.lib-sidebar .sidebar-social__wrapper h3 {
    font-size: var(--fz-normal);
    font-family: var(--accent-ff);
    font-weight: normal;
}

.lib-sidebar .sidebar-social__wrapper {
    border-top: 1px solid var(--color-grayish);
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.lib-sidebar .sidebar-social {
    display: flex;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
}
.lib-sidebar .sidebar-social .sidebar-social__facebook {
    background-color: #506098;
}
.lib-sidebar .sidebar-social .sidebar-social__viber {
    background-color: #7d3daf;
}
.lib-sidebar .sidebar-social .sidebar-social__telegram {
    background-color: #08c;
}
.lib-sidebar .sidebar-social .sidebar-social__instagram {
    background: linear-gradient(45deg,#ffb700 14.65%,#ff6800 31.68%,#cb217d 54.72%,#7e00aa 85.35%);
}

.lib-sidebar .sidebar-social a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    opacity: .85;
    transition: all .2s ease;
}
