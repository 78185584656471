.card-pop-up {
    font-family: var(--accent-ff);
    margin: var(--size-normal) auto;
    margin-top: 60px;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    border-radius: var(--size-smaller);
    border: 1px solid var(--color-border-lightest);
    width: 270px;
    background: var(--color-lightest);

    --price-popup-color: var(--color-accent);
}

.card-pop-up__title {
    display: flex;
    justify-content: center;
    margin-top: var(--size-small);
    margin-bottom: var(--size-small);
    font-size: var(--fz-tiny);
    text-transform: uppercase;
    color: var(--color-accent);
}

.card-pop-up__info {
    display: flex;
    margin-bottom: var(--size-mid);
}

.card-pop-up__img {
    display: flex;
    justify-content: center;
    width: 50%;
}
img.card-pop-up__img {
    width: 95px;
    height: 110px;
}

.card-pop-up__product-name {
    color: var(--color-accent);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-pop-up__size {
    color: var(--color-accent);
}

.card-pop-up__price .sale{
    color: var(--color-accent-light)!important;
    font-size: var(--fz-tiny);
    text-decoration: line-through;
    line-height: 1.4;
    margin-right: 0;
    position: relative;
    white-space: nowrap;
}


.card-pop-up__price {
    color: var(--price-popup-color);
}
