.lib-card__heart {
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.lib-card__heart:focus{
   outline: none;
}

.lib-card__heart:hover {
    animation: .9s linear 0s infinite alternate pulse;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

